import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
import { RadioGroup } from "@mui/material";
import BaseRadio from "../../../components/ui/BaseRadio";
import HardStart from "./ScheduleFormTypes/HardStart";
import WorkBetween from "./ScheduleFormTypes/WorkBetween";
import WorkAnytime from "./ScheduleFormTypes/WorkAnytime";

const TIME_SHORTCUTS = [];

const ScheduleForm = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.workOrder.scheduling.schedule);

  const handleChange = (name, value) => {
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            [name]: value,
          },
        },
      })
    );
  };

  const handleSetTomorrow8AM = () => {
    const tomorrowAt8AM = dayjs()
      .add(1, "day")
      .hour(8)
      .minute(0)
      .second(0)
      .millisecond(0);

    // Format the date and time
    const formattedDateTime = tomorrowAt8AM.toISOString();
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            orderStartDate: formattedDateTime,
            orderStartTime: formattedDateTime,
          },
        },
      })
    );
  };
  const handleSetToday = (time) => {
    const today = dayjs().hour(time).minute(0).second(0);

    // Format the date and time
    const formattedDateTime = today.toISOString();

    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            orderStartDate: formattedDateTime,
            orderStartTime: formattedDateTime,
          },
        },
      })
    );
  };

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Schedule</h6>
      </div>

      <div className="col-span-2">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="HARD_START"
          name="radio-buttons-group"
          onChange={(e) => handleChange("scheduleType", e.target.value)}
        >
          <div>
            <BaseRadio
              label="A specific date & time (Hard Start)"
              value="HARD_START"
            />

            <BaseRadio
              label="Complete Work Between specific hours"
              value="WORK_BETWEEN"
            />

            <BaseRadio
              label="Complete Work anytime over a date range"
              value="WORK_ANYTIME"
            />
          </div>
        </RadioGroup>
      </div>

      {schedule.scheduleType === "HARD_START" && <HardStart />}
      {schedule.scheduleType === "WORK_BETWEEN" && <WorkBetween />}
      {schedule.scheduleType === "WORK_ANYTIME" && <WorkAnytime />}

      <p className="text-sm font-medium text-dark my-4">
        Quick options:
        <span
          className="text-secondary font-light cursor-pointer ml-2"
          onClick={handleSetTomorrow8AM}
        >
          Tomorrow 8am
        </span>
        <span
          className="text-secondary font-light cursor-pointer ml-2"
          onClick={() => handleSetToday(10)}
        >
          , 10am
        </span>
        <span
          className="text-secondary font-light cursor-pointer ml-2"
          onClick={() => handleSetToday(12)}
        >
          , 12pm
        </span>
        <span
          className="text-secondary font-light cursor-pointer ml-2"
          onClick={() => handleSetToday(17)}
        >
          , 5pm
        </span>
        <span className="text-secondary font-light cursor-pointer ml-2">
          , This week
        </span>
        <span className="text-secondary font-light cursor-pointer ml-2">
          , Next week
        </span>
      </p>
    </BaseCard>
  );
};

export default ScheduleForm;
