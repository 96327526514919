import axios from "../../lib/axiosInstance";

export function createWorkOrder(payload) {
  return axios.post("workOrders", payload).then((res) => res.data);
}

export function fetchUserWorkOrders(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.status) {
    queryParams.append("status", payload.status);
  }

  return axios
    .get("workOrders", { params: queryParams })
    .then((res) => res.data);
}

export function findJobs(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }

  return axios
    .get("workOrders/find-job", { params: queryParams })
    .then((res) => res.data);
}
export function fetchJobDetail(id) {
  return axios.get(`workOrders/${id}`).then((res) => res.data);
}

export function fetchFreelancerJobs(payload) {
  return axios.post(`workOrders/freelancer`, payload).then((res) => res.data);
}

export function fetchJobsCount(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }
  return axios
    .get(`workOrders/jobs-count`, { params: queryParams })
    .then((res) => res.data);
}

export function updateWorkOrder(payload) {
  return axios
    .patch(`workOrders/${payload.id}`, payload)
    .then((res) => res.data);
}
