import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const ServiceDescription = ({ description, confidentialInformation }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Service Description</h6>
        <i class="fa-solid fa-pencil ml-3"></i>
      </div>
      {description && (
        <div>
          <p className="text-base font-medium underline underline-offset-2">
            Work Description
          </p>
          <div
            className="min-h-72"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      )}
      {confidentialInformation && (
        <div>
          <p className="text-base font-medium underline underline-offset-2">
            Confidential Information
          </p>
          <div
            className="min-h-72"
            dangerouslySetInnerHTML={{ __html: confidentialInformation }}
          ></div>
        </div>
      )}
    </BaseCard>
  );
};

export default ServiceDescription;
