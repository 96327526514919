import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const WorkExperience = ({ category, services }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Work Experience</h6>
        <i class="fa-solid fa-pencil ml-3"></i>
      </div>
      <div>
        <div className="bg-[#F6F6F6] py-2 px-3 rounded-md flex items-center mb-2.5">
          <p className="font-normal w-48">Technology Stacks</p>
          <p className="font-light bg-[#D9D9D9] rounded-sm px-1.5 p-[1px]">
            {category?.category}
          </p>
        </div>

        <div className="bg-[#F6F6F6] py-2 px-3 rounded-md flex items-center mb-2.5">
          <p className="font-normal w-48">Vendors</p>
          {/* <p className="font-light bg-[#D9D9D9] rounded-sm px-1.5 p-[1px]">
            Cisco
          </p> */}
        </div>
        <div className="bg-[#F6F6F6] py-2 px-3 rounded-md flex items-center">
          <p className="font-normal w-48">Products</p>
          {services?.map((service) => (
            <p className="font-light bg-[#D9D9D9] rounded-sm px-1.5 p-[1px] mr-4">
              {service?.services?.subCategory}
            </p>
          ))}
        </div>
      </div>
    </BaseCard>
  );
};

export default WorkExperience;
