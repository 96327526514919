import React, { useState } from "react";

import BaseSwitch from "../../../components/ui/BaseSwitch";
import BaseButton from "../../../components/ui/BaseButton";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { createWorkOrder } from "../../../services/api";
import { showToast } from "../../../store/toast/toastSlice";
import { navigateWithReload } from "../../../utils/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateWorkOrder } from "../../../services/api/workOrder";
import { isDraft } from "@reduxjs/toolkit";
import { resetWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
const WorkOrderHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const isEdit = location.pathname.split("/")[2]?.toLowerCase() === "edit";
  const workOrder = useSelector((state) => state.workOrder);

  const {
    isPending: isPublishLoading,
    status,
    error,
    mutateAsync: workOrderMutation,
  } = useMutation({
    mutationFn: createWorkOrder,
    onSuccess: (data) => {
      console.log("response ", data);
    },
  });

  const {
    isPending: isUpdatingLoading,
    error: isUpdatingError,
    mutateAsync: updateWorkOrderMutation,
  } = useMutation({
    mutationFn: updateWorkOrder,
    onSuccess: (data) => {
      console.log("response ", data);
    },
  });

  const handleWorkOrderUpdateOrPublish = async (isDraft) => {
    if (isEdit) {
      editWorkWork();
    } else {
      handleWorkOrderPublish(isDraft);
    }
  };

  const editWorkWork = async () => {
    const payload = {
      id: params.id,
      ...workOrder.summary.overview,
      ...workOrder.summary.serviceDescription,
      ...workOrder.payment.pay,
    };
    await updateWorkOrderMutation(payload);
    dispatch(
      showToast({
        message: "Work Order Updated",
        type: "success",
      })
    );

    try {
    } catch (error) {
      dispatch(
        showToast({
          message: error.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleWorkOrderPublish = async (isDraft) => {
    try {
      const payload = formatWorkOrderPayload(isDraft);
      await workOrderMutation(payload);
      dispatch(
        showToast({
          message: isDraft ? "Work Order Drafted" : "Work Order Published",
          type: isDraft ? "info" : "success",
        })
      );

      navigate(`/work-orders`);
    } catch (error) {
      console.log("error", error);
      if (error.response?.status === 401) {
        window.localStorage.clear();
        navigateWithReload("company-signup");
      }
      if (typeof error.response?.data?.message === "string") {
        dispatch(
          showToast({
            message: error.response?.data?.message,
            type: "error",
          })
        );

        return;
      }

      for (let i = 0; i < error?.response?.data?.message.length; i++) {
        const errorMessage = error?.response?.data.message[i];
        dispatch(
          showToast({
            message: errorMessage,
            type: "error",
          })
        );
      }
    }
  };

  const formatWorkOrderPayload = (isDraft) => {
    const formatedWorkOrderServices =
      workOrder.summary.workExperience?.workOrderServices.map((service) => {
        return { serviceId: service };
      });

    let location =
      workOrder.scheduling.location.type === "SAVED_LOCATION"
        ? { addressId: workOrder.scheduling.location.addressId }
        : workOrder.scheduling.location.type === "REMOTE"
        ? { isRemote: true }
        : workOrder.scheduling.location;

    const payload = {
      ...workOrder.summary.overview,
      ...workOrder.summary.serviceDescription,
      categoryId: workOrder.summary.workExperience.categoryId,
      workOrderServices: formatedWorkOrderServices,
      ...workOrder.scheduling.schedule,
      ...location,
      ...workOrder.scheduling.qualification,
      ...workOrder.payment.pay,

      isDraft,
      tasks: formatWorkOrderTasks(workOrder.payment.tasks),
      contacts: formatWorkOrderContacts(workOrder.scheduling.contacts.contacts),
    };

    return payload;
  };

  const formatWorkOrderTasks = (tasksList) => {
    return tasksList.map((task) => {
      const { taskType, emailsToNotify, phone, reason, ...rest } = task;
      const sanitizeEmails = emailsToNotify.map((emailObj) => emailObj.email);

      return {
        taskType,
        phone,
        reason,
        emailsToNotify: sanitizeEmails,
      };
    });
  };

  const formatWorkOrderContacts = (contacts) => {
    console.log("contactsss", contacts);
    return contacts?.map((contact) => {
      const { name, phoneNumber, note, ...rest } = contact;

      return {
        name,
        phoneNumber,
        note,
      };
    });
  };

  return (
    <div className="border-b border-zinc-300 bg-white h-[78px]">
      <BaseWrapper>
        <div className="grid grid-cols-7 py-4 items-center ">
          <div className="col-span-2">
            <h3 className="font-light">
              {isEdit ? "Edit Work Order" : "New Work Order"}
            </h3>
          </div>
          <div className="col-span-5 flex  items-center justify-end">
            {/* <div className="flex items-center justify-between bg-[#F6F6F6] p-3 rounded-lg w-52 mr-4 ">
              <BaseSwitch />
              <p>Advanced Fields</p>
            </div> */}
            {/* <div className="mr-4">
              <BaseButton
                variant="contained"
                color="primary"
                style={{
                  border: "1px solid #0F40AE",
                }}
                className="bg-[#F6F6F6] pt-2 pb-2"
              >
                <p className="text-primary text-sm">Auto Dispatch</p>
              </BaseButton>
            </div> */}
            {!isEdit && (
              <div className="mr-4">
                <BaseButton
                  onClick={() => {
                    handleWorkOrderPublish(true);
                  }}
                  style={{
                    border: "1px solid #0F40AE",
                  }}
                  className="bg-[#F6F6F6] pt-[7px] pb-[7px]"
                >
                  <p className="text-primary text-sm">Save As Draft</p>
                </BaseButton>
              </div>
            )}
            <div>
              <BaseButton
                isLoading={isPublishLoading}
                variant="contained"
                color="primary"
                className="pt-2 pb-2"
                onClick={() => {
                  handleWorkOrderUpdateOrPublish(false);
                }}
              >
                <p className="text-white text-sm">
                  {isEdit ? "Update" : "Publish"}
                </p>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkOrderHeader;
