import React from "react";
import BaseDatePicker from "../../../../components/ui/BaseDatePicker";
import BaseTextField from "../../../../components/ui/BaseTextField";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateWorkOrderForm } from "../../../../store/workOrder/workOrderSlice";

const HardStart = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.workOrder.scheduling.schedule);
  const handleChange = (name, value) => {
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            [name]: value,
          },
        },
      })
    );
  };

  console.log("schedule", schedule);

  return (
    <div className="grid grid-col-2 gap-y-4">
      <div className="col-span-2 bg-[#F2FAFF] py-3 px-4 rounded-md my-3">
        <div className="flex items-center">
          <i class="fa-solid fa-circle-info text-[#7AC7FF] text-lg"></i>

          <div className="ml-2">
            <p className="font-medium ">
              Supplier for this type of work order must reach 15 minutes before
              the work order schedule time.
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-2 flex items-center">
        <div className="w-full">
          <BaseDatePicker
            type="date"
            name="orderStartDate"
            value={dayjs(schedule.orderStartDate)}
            onChange={(e) => handleChange("orderStartDate", dayjs(e).format())}
          />
        </div>
        <p className="mx-9 text-dark">at</p>
        <div className="w-full">
          <BaseDatePicker
            // defaultValue={dayjs("2022-04-17T00:30:00+05:00")}
            name="orderStartTime"
            type="time"
            value={dayjs(schedule.orderStartTime)}
            onChange={(e) => handleChange("orderStartTime", dayjs(e).format())}
          />
        </div>
      </div>

      <div className="col-span-2 flex items-center">
        <div className="w-full ">
          <BaseTextField
            fullHeight={false}
            fullWidth
            label="Time Zone"
            labelShrink={false}
            required={true}
            disabled
            optional={false}
            name="timeZone"
            value={schedule.timeZone}
            onChange={(e) => handleChange("timeZone", e.target.value)}
          />
        </div>
        <p className="mx-10 text-dark"></p>
        <div className="flex items-center w-full mt-6 ">
          <p className="">Time zone is set according to the work location.</p>
        </div>
      </div>
    </div>
  );
};

export default HardStart;
