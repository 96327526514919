import React from "react";
import Overview from "../TabsCard/Overview";
import ServiceDescription from "../TabsCard/ServiceDescription";
import Tasks from "../TabsCard/Tasks";
import { useSelector } from "react-redux";
import WorkExperience from "../TabsCard/WorkExperience";
import Qualification from "../TabsCard/Qualification";
import ProviderAssessment from "../TabsCard/ProviderAssessment";
import Documents from "../TabsCard/Documents";
import CustomField from "../TabsCard/CustomField";
import TimeLog from "../TabsCard/TimeLog";
import Deliverables from "../TabsCard/Deliverables";
import Shipments from "../TabsCard/Shipments";
import SmartAudit from "../TabsCard/SmartAudit";

const DetailsTab = () => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  return (
    <div>
      <Overview workOrderDetail={workOrderDetail} />
      <ServiceDescription
        description={workOrderDetail?.description}
        confidentialInformation={workOrderDetail?.confidentialInformation}
      />
      <Tasks tasks={workOrderDetail?.tasks} />
      <WorkExperience
        category={workOrderDetail?.category}
        services={workOrderDetail?.services}
      />
      <Qualification />
      <Documents />
      <TimeLog />
      <Deliverables />
      <Shipments />
      <ProviderAssessment />
      <CustomField />
      <SmartAudit />
    </div>
  );
};

export default DetailsTab;
