import React, { useEffect, useState } from "react";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useQuery } from "@tanstack/react-query";
import { fetchUserWorkOrders } from "../../../services/api/workOrder";
import dayjs from "dayjs";
import WorkOrderStatus from "../../../components/WorkOrderStatus";
import BaseDataTable from "../../../components/ui/BaseDataTable";
import FilterChip from "../../../components/FilterChip";
import { useNavigate } from "react-router-dom";
import { navigateWithReload } from "../../../utils/helpers";
import BaseDropdown from "../../../components/ui/BaseDropdown";

const WorkOrderTable = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
  });
  const [workOrders, setWorkOrders] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track open dropdown by work order ID
  const [statusCount, setStatusCount] = useState({});

  const {
    isLoading: isWorkOrderLoading,
    error: isWorkOrderError,
    data: workOrdersData,
  } = useQuery({
    queryKey: ["userWorkOrders"],
    queryFn: fetchUserWorkOrders,
  });

  if (isWorkOrderError?.response?.status === 401) {
    window.localStorage.clear();
    navigateWithReload("/company-signup");
  }

  const data = workOrders || [];
  const columnWidths = {
    ID: "120px",
    Title: "296px",
    Location: "22%",
    Pay: "15%",
    Schedule: "18%",
    Status: "12%",
    "Additional Column": "50%",
  };
  const COLUMNS = [
    {
      label: "ID",
      renderCell: (item) => <p className="text-sm font-normal">{item.id}</p>,
      pinLeft: true,
      sort: { sortKey: "ID" },
    },
    {
      label: "Title",
      renderCell: (item) => (
        <>
          <p
            className="text-sm text-primary cursor-pointer"
            onClick={() => handleNavigate(item.id)}
          >
            {item.title}
          </p>
        </>
      ),
      sort: { sortKey: "TITLE" },
    },
    {
      label: "Location",
      renderCell: (item) => (
        <p className="text-sm">
          {`${item.address.addressLine1}, ${item.address.country}`}
        </p>
      ),
      // sort: { sortKey: "LOCATION" },
    },
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Schedule",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">
            {item.scheduleType === "HARD_START" ? "Hard Time" : ""}
          </p>
          <p className="text-sm">
            {dayjs(item.orderStartDate).format("MM/DD/YYYY")}

            <span> at </span>

            {dayjs(item.orderStartTime).format("hh:mm A")}
            <span> ({item.timeZone})</span>
          </p>
        </div>
      ),
      // sort: { sortKey: "SCHEDULE" },
    },
    {
      label: "Status",
      renderCell: (item) => <WorkOrderStatus status={item.status} />,
      // sort: { sortKey: "STATUS" },
    },
    {
      label: <i className="fa-solid fa-gear text-[20px] mt-1"></i>,
      renderCell: (item) => (
        <div className="relative overflow-visible">
          <i
            className="fa-solid fa-ellipsis text-[18px] cursor-pointer"
            onClick={() => toggleDropdown(item.id)}
          ></i>
          {openDropdownId === item.id && (
            <BaseDropdown
              key={item.id}
              open={true}
              className="w-[186px]  h-auto top-[6px] left-[-190px] shadow-none "
              style={{
                boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer border-b rounded-t-lg"
                onClick={() => navigate(`/work-orders/edit/summary/${item.id}`)}
              >
                <p className="text-sm capitalize">Edit</p>
              </div>
              <div className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer border-b">
                <p className="text-sm capitalize">Publish</p>
              </div>
              <div className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer rounded-b-lg">
                <p className="text-sm capitalize ">Cancel</p>
              </div>
            </BaseDropdown>
          )}
        </div>
      ),
      pinRight: false,
    },
  ];

  const handleNavigate = (id) => {
    navigate(`/find-job/${id}`);
  };

  const isTodayOrTomorrow = (date) => {
    const today = dayjs().startOf("day");
    const tomorrow = today.add(1, "day");
    const itemDate = dayjs(date).startOf("day");
    return itemDate.isSame(today, "day") || itemDate.isSame(tomorrow, "day");
  };
  const getStatusCounts = () => {
    if (!workOrdersData?.data.workOrders) return {};

    return workOrdersData?.data.workOrders.reduce((acc, workOrder) => {
      const { status, orderStartDate } = workOrder;
      if (!status) return acc;

      if (isTodayOrTomorrow(orderStartDate)) {
        acc["INFLIGHT"] = (acc["INFLIGHT"] || 0) + 1;
      }
      acc[status] = (acc[status] || 0) + 1;

      return acc;
    }, {});
  };

  //this will initially run to set the work orders data fetched from API
  useEffect(() => {
    setWorkOrders(workOrdersData?.data.workOrders);
    setStatusCount(getStatusCounts());
  }, [workOrdersData]);

  //this is filter the work order based on current filter selected without API call
  useEffect(() => {
    if (filters.status === "") {
      setWorkOrders(workOrdersData?.data.workOrders);
    } else if (filters.status === "INFLIGHT") {
      const filteredWorkOrders = filterInFlightWorkOrders();
      setWorkOrders(filteredWorkOrders);
    } else {
      const filteredWorkOrders = workOrdersData?.data.workOrders.filter(
        (workOrder) => workOrder.status === filters.status
      );

      setWorkOrders(filteredWorkOrders);
    }
  }, [filters]);

  const filterInFlightWorkOrders = () => {
    const today = dayjs().startOf("day");
    const tomorrow = today.add(1, "day");

    const filteredWorkOrders = workOrdersData?.data.workOrders.filter(
      (workOrder) => {
        const workOrderDate = dayjs(workOrder.orderStartDate).startOf("day");
        return (
          workOrderDate.isSame(today, "day") ||
          workOrderDate.isSame(tomorrow, "day")
        );
      }
    );

    return filteredWorkOrders;
  };

  const toggleDropdown = (id) => {
    if (openDropdownId === id) {
      setOpenDropdownId(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdownId(id); // Open the dropdown for the selected row
    }
  };
  return (
    <BaseWrapper>
      <div className="bg-white rounded-lg p-5 shadow-md my-4">
        <div className="flex">
          <div className="flex items-center border-r-[1px] border-[#8C8C8C] pr-5">
            <i className="fa-solid fa-circle-plus text-secondary text-[22px]"></i>
            <p className="text-secondary ml-2">Add Filter</p>
          </div>
          <div className="flex items-center pl-5">
            <p className="font-medium">
              Assigned Provider:{" "}
              <span className="font-light">Samuel Nyandoro</span>
            </p>
            <i className="ml-3 mb-[2px] fa-solid fa-chevron-down"></i>
          </div>
        </div>
      </div>

      <div className="my-4 flex justify-between">
        <FilterChip
          filter="Ongoing"
          count={statusCount.INFLIGHT ? statusCount.INFLIGHT : 0}
          active={filters.status === "INFLIGHT" ? true : false}
          onClick={() => setFilters({ status: "INFLIGHT" })}
        />
        <FilterChip
          filter="Draft"
          count={statusCount.DRAFT ? statusCount.DRAFT : 0}
          active={filters.status === "DRAFT" ? true : false}
          onClick={() => setFilters({ status: "DRAFT" })}
        />
        <FilterChip
          filter="Published\Routed"
          count={statusCount.PUBLISHED ? statusCount.PUBLISHED : 0}
          active={filters.status === "PUBLISHED" ? true : false}
          onClick={() => setFilters({ status: "PUBLISHED" })}
        />
        <FilterChip
          filter="Assigned"
          count={statusCount.ASSIGNED ? statusCount.ASSIGNED : 0}
          active={filters.status === "ASSIGNED" ? true : false}
          onClick={() => setFilters({ status: "ASSIGNED" })}
        />
        <FilterChip
          filter="Done"
          count={statusCount.DONE ? statusCount.DONE : 0}
          active={filters.status === "DONE" ? true : false}
          onClick={() => setFilters({ status: "DONE" })}
        />
        <FilterChip
          filter="Approved"
          count={statusCount.APPROVED ? statusCount.APPROVED : 0}
          active={filters.status === "APPROVED" ? true : false}
          onClick={() => setFilters({ status: "APPROVED" })}
        />
        <FilterChip
          filter="Issue"
          count={statusCount.ISSUE ? statusCount.ISSUE : 0}
          active={filters.status === "ISSUE" ? true : false}
          onClick={() => setFilters({ status: "ISSUE" })}
        />
        <FilterChip
          filter="All"
          count={workOrdersData?.data.workOrders?.length}
          active={filters.status === "" ? true : false}
          onClick={() => setFilters({ status: "" })}
        />
      </div>

      <BaseDataTable
        columns={COLUMNS}
        nodes={data}
        columnWidths={columnWidths}
      />
    </BaseWrapper>
  );
};

export default WorkOrderTable;
